@import ../../../../styles/helpers

.section
    +dark
        background: none

.stage
    margin-bottom: 16px
    text-align: center

.title
    max-width: 800px
    margin: 0 auto 30px
    text-align: center

.content
    max-width: 800px
    margin: 0 auto 30px
    text-align: left
    font-weight: 400

.section
    margin-bottom: 16px
    text-align: left
    font-weight: 600

.preview
    margin: 0 auto 30px
    img
        min-width: 800px
        border-radius: 16px
        margin: auto
        display: block
        +m
            min-width: 100px
            max-width: 200px
            border-radius: 12px

.previewmd
    margin: 0 auto 30px
    img
        width: 75%
        border-radius: 16px
        margin: auto
        display: block
        +m
            min-width: 200px
            max-width: 100%
            border-radius: 12px

.previewlg
    margin: 0 auto 30px
    img
        width: 50%
        border-radius: 16px
        margin: auto
        display: block
        +m
            min-width: 200px
            max-width: 100%
            border-radius: 12px


.offer
    margin-bottom: 16px
    text-align: center
    margin-bottom: 50px
    +m
        margin-bottom: 64px

.row
    display: flex
    margin-top: -5px
    +m
        display: block

.col
    flex: 1 1
    +m
        padding: 48px 32px 64px
        background: $neutrals8
        border-radius: 24px
        +dark
            background: $neutrals2
    &:first-child
        flex: 0 0 350px
        +d
            flex: 0 0 220px
        +t
            flex: 0 0 130px
        +m
            display: none
    /*&:nth-child(3)
        border-radius: 24px
        background: $neutrals8
        +dark
            background: $neutrals2
            .parameter,
            .tick
                border-color: rgba($neutrals4, .3) */
    
    &:not(:first-child)
        padding-bottom: 54px
        text-align: center
        +m
            text-align: left
        .body
            padding-top: 28px
        .parameter
            justify-content: center
            text-align: center
            +m

        .label
            display: none
            +m
                display: block
    &:not(:last-child)
        +m
            margin-bottom: 32px

    
.head
    min-height: 256px
    padding: 48px 32px 44px
    +t
        padding: 48px 16px 44px
    +m
        min-height: auto
        padding: 0

.more
    display: none
    +m
        display: flex
        align-items: center
        +caption-2
        font-weight: 600
        &.active
            svg
                transform: rotate(180deg)
    svg
        margin-left: 12px
        fill: $neutrals4
        transition: transform .2s


.package
    margin-bottom: 8px
    font-weight: 600

.description
    +caption-1
    font-size: 16px
    color: $neutrals4

.cost
    position: relative
    display: inline-block
    margin-top: 32px
    +m
        margin-left: 15px

.price
    +sf-pro-display
    font-size: 48px
    line-height: 56px
    letter-spacing: -.02em

.sign
    position: absolute
    top: 0
    right: calc(100% + 4px)
    +body-1

.note
    +caption-2
    color: $neutrals4

.list
    +m
        display: none
        padding-top: 24px
        &.visible
            display: block

.category
    margin-bottom: 16px
    +hairline-2
    color: $neutrals4

.parameter,
.tick
    display: flex
    align-items: center
    height: 64px
    border-bottom: 1px solid $neutrals6
    +dark
        border-color: $neutrals2
    svg
        fill: $green

.label
    margin-right: auto
    font-weight: 600

.hint
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 20px
    height: 20px
    flex-shrink: 0
    margin-left: 20px
    border-radius: 50%
    border: 2px solid $neutrals5
    cursor: pointer
    +t
        display: none
    svg
        fill: $neutrals5
    &:hover
        .tooltip
            visibility: visible
            opacity: 1

.tooltip
    position: absolute
    top: 50%
    left: calc(100% + 20px)
    min-width: 260px
    padding: 6px 12px
    transform: translateY(-50%)
    border-radius: 4px
    background: $neutrals8
    box-shadow: 0 4px 12px rgba($neutrals2, .1)
    font-size: 14px
    visibility: hidden
    opacity: 0
    transition: all .2s
    &:before
        content: ""
        position: absolute
        right: 100%
        top: 50%
        transform: translateY(-50%)
        +arr(6,12,$neutrals8,l)
    +dark
        background: $neutrals3
        box-shadow: 0 4px 12px rgba($neutrals1, .1)
        &:before
            +arr(6,12,$neutrals3,l)
    
.tick
    justify-content: center
    svg
        fill: $green

.minus
    padding: 0 2px
    font-size: 18px
    font-weight: 600
    color: $neutrals4

.button
    margin-top: 54px

.preview
    position: relative
    margin-bottom: 32px
    img
        width: 100%
        margin-top: 20px
        max-width: 400px
        border-radius: 16px
        +m
            border-radius: 12px