@import ../../styles/helpers


.head
    max-width: 580px
    margin: 0px auto 20px
    text-align: center
    +d
        margin-bottom: 0px
    +m
        text-align: left
        margin-bottom: 60px

.stage
    margin-bottom: 8px
    color: $neutrals4

.list
    display: flex
    margin-bottom: 80px
    +m
        display: block

.item
    flex: 0 0 31%
    padding: 34px 83px
    +x
        padding: 34px 64px
    +d
        padding: 32px
    +m
        padding: 0 
    &:first-child
        padding-left: 0
    &:nth-child(2)
        flex: 0 0 38%
        border-width: 0 1px
        border-style: solid
        border-color: $neutrals6
        padding-bottom: -30px 
        +m
            border: none
        +dark
            border-color: $neutrals3
    &:last-child
        padding-right: 0
    &:not(:last-child)
        +m
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $neutrals6
            +dark
                border-color: $neutrals3

.icon
    margin-bottom: 32px
    svg
        +dark
            fill: $neutrals8

.category
    margin-bottom: 32px
    font-weight: 400
    position: relative
    border-bottom: 1px dashed $neutrals4
    text-decoration: none
    white-space: normal
    color: $neutrals4
    &:hover
        font-weight: 700

    
.content
    +caption-1
    color: $neutrals4

.preview
    position: relative
    margin-bottom: 32px
    justify-content: center
    align-items: center
    img
        max-height: 175px
        border-radius: 16px
        display: block
        margin-left: auto
        margin-right: auto
        +m
            border-radius: 12px


.hint
    position: relative
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 20px
    flex-shrink: 0
    border-radius: 1%
    border: 0px solid $neutrals5
    cursor: pointer
    svg
        fill: $neutrals5
    &:hover
        .tooltip
            visibility: visible
            opacity: 1

.tooltip
    position: absolute
    top: 50%
    left: calc(20% + 20px)
    min-width: 260px
    padding: 6px 12px
    transform: translateY(-50%)
    border-radius: 4px
    background: $neutrals8
    box-shadow: 0 4px 12px rgba($neutrals2, .1)
    font-size: 14px
    visibility: hidden
    opacity: 0
    transition: all .2s
    z-index: 3
    &:before
        content: ""
        position: absolute
        right: 100%
        top: 50%
        transform: translateY(-50%)
        +arr(6,12,$neutrals8,l)
    +dark
        background: $neutrals3
        box-shadow: 0 4px 12px rgba($neutrals1, .1)
        &:before
            +arr(6,12,$neutrals3,l)