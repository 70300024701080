@import ../../styles/helpers

.section
    text-align: center
    margin-bottom: 100px
    margin-top: 50px

.stage
    margin-bottom: 16px

.title
    max-width: 650px
    margin: 0 auto 32px
    +t
        max-width: 550px

.text
    max-width: 500px
    margin: 0 auto 48px
    color: $neutrals4

.preview
    position: relative
    margin-bottom: 32px
    img
        width: 100%
        margin-top: 20px
        max-width: 800px
        border-radius: 16px
        +m
            border-radius: 12px

.wrap
    margin-top: 30px
    +m
        margin-top: 5px

.bluebtns
    margin-top: 20px
    text-align: center
    +m
        margin-top: 15px
    .button
        font-size: 20px
        background: $blue
        width: 160px
        height: 50px
        &:first-child
            margin-right: 12px
        &:nth-child(2)
            flex-grow: 1

.video 
    width: 100%
    height: 300px
    margin-top: 10px
    filter: brightness(100%)
    object-fit: cover
    border-radius: 20px
    max-width: 450px
    +m 
        object-fit: cover
        width: 100%
        margin-top: 10px
        display: block
        margin-left: auto
        margin-right: auto