@import ../../../styles/helpers

.wrapper
    position: relative
    margin-top: 80px
    text-align: center

.title
    margin-bottom: 15px
    +m
        margin-bottom: 12px
        text-align: center

.text
    margin-bottom: 50px
    max-width: 600px
    margin: 0 auto 48px
    color: $neutrals4
    +m
        margin-bottom: 24px
        text-align: center

.wrap
    margin: 0 -16px

.card
    height: 100%

.btns
    margin-top: 64px
    text-align: center
    +m
        margin-top: 48px
